@import url('https://fonts.googleapis.com/css2?family=Anton&family=Noto+Sans+KR:wght@100..900&display=swap');


@font-face {
  font-family: "Poppins-Bold";
  src: url("../font/Poppins-Bold.woff") format("woff");
  font-weight: 600;
  font-style: bold;
}

@font-face {
  font-family: "Poppins-Regular";
  src: url("../font/Poppins-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Pretendard-Regular";
  src: url("https://fastly.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

* {
  box-sizing: border-box;

}

body {
  overflow-x: hidden;
  background-color: #111;
  color: #fff;
  transition: 0.7s all;
  font-family: "Poppins-Regular";
}

body.active {
  background-color: #fff;
  color: #000;

}


main {
  overflow-x: hidden;
  overflow-y: hidden;
}

.inner_wrap {
  width: 1384px;
  max-width: 100%;
  padding: 0 20px;
  margin: 0 auto;
}


header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  z-index: 3;
  width: 100%;
  height: 120px;
  padding: 0 100px;
  box-sizing: border-box;
  transition: padding 0.35s, height 0.35s;
  color: #fff;
  left: 0;
  top: 0;
}

header .logo {
  width: 200px;
}

header nav .deth-1>li {
  position: relative;
}

header nav .deth-1>li:hover:before {
  opacity: 1;
  transform: translateY(0);
}

header nav .deth-1>li:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(255,225,233,0.1);
  content: "";
  display: block;
  z-index: -1;
  transform: translateY(-100%);
  transition: opacity 0.3s;
  opacity: 0;
}

header nav .deth-1>li a {
  display: block;
  width: 100%;
  padding: 50px 0 50px 0;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  line-height: 1;
}

header nav .deth-1>li .deth-2 {
  position: absolute;
  top: calc(100% - 20px);
  transform: scale(0);
  opacity: 0;
  width: 100%;
  padding: 0 0 40px 0;
  transition: opacity 0.3s;
}

header nav .deth-1>li:hover .deth-2::before {
  content: "";
  position: absolute;
  top: 20px;
  left: 0;
  z-index: -1;
  width: 100%;
  height: calc(100% - 20px);
  background: #4806c5;
}

header nav .deth-1>li:hover .deth-2 {
  opacity: 1;
  transform: scale(1);
}

header nav .deth-1>li .deth-2 li:hover {
  color: #fff;
}

header nav .deth-1>li .deth-2 li {
  text-align: center;
  color: rgba(255, 255, 255, 0.3);
}

header nav .deth-1>li .deth-2 li a {
  padding: 13px 0;
  font-size: 17px;
  font-family: "Poppins Regular";
}

nav {
  width: calc(100% - 200px);
  height: 100%;
}

#menu {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 120px;

  width: 100%;
  text-align: center;
  font-size: 1.2rem;
}

#menu>li {
  width: calc(100% / 8.2);
  line-height: 120px;
}

#menu>li a {
  display: block;
  width: 100%;
}

.not_scroll #m_menu {
  display: block;
}

.not_scroll #m_menu {
  animation: showMenu .2s forwards;
}

@keyframes showMenu {
  0% {
    left: 100%;
  }

  100% {
    left: 0;
  }
}


#m_menu {
  display: none;
  background-color: #000;
  color: #fff;
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 100;
  font-size: 2em;
  padding: 1.5em 1.5em 0;
  left: 100%;
}


#m_menu li {
  width: 80%;
  margin-bottom: 20px;
}

.banner {
  width: 100%;
  height: 105vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: relative;
}


.banner img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
}


.banner video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
}


.banner .video{
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
}

.banner .video iframe {
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
}

.ytp-gradient-top {
  display: none
}


/* 컨트롤러 전체 감추기 */

video::-webkit-media-controls {
  display: none !important;
}



/* 컨트롤러 중 원하는 부분만 감추기 */



/* 전체화면 버튼 */

video::-webkit-media-controls-fullscreen-button {
  display: none !important;
}



/* 일시정지, 재생 버튼 */

video::-webkit-media-controls-play-button {
  display: none !important;
}



/* 재생 슬라이드..? */

video::-webkit-media-controls-timeline {
  display: none !important;
}



/* 현재 진행 시간 */

video::-webkit-media-controls-current-time-display {
  display: none !important;
}



/* 전체 시간 */

video::-webkit-media-controls-time-remaining-display {
  display: none !important;
}



/* 음소거 버튼 */

video::-webkit-media-controls-mute-button {
  display: none !important;
}



/* 볼륨 조절 슬라이드 */

video::-webkit-media-controls-volume-slider {
  display: none !important;
}



/* 볼륨 조절 슬라이드 */

video::-webkit-media-controls-volume-slider {
  display: none !important;
}

.banner::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
  left: 0;
  top: 0;
}

.banner h1 {
  position: absolute;
  left: 5%;
  bottom: 10vw;
  z-index: 3;
  font-size: 8rem;
  color: #fff;
}

@media (max-width: 1800px) {
  header {
    padding: 0 50px;
  }
}

.facility .swiper-wrapper {
  margin-top: 20px;
}

.swiper-container-facility {
  padding: 0 20px;
  overflow: hidden;
}


footer {
  background: #222222;
  position: relative;
  width: 100%;
  height: auto;
  color: #fff;
  margin-top: 7em;
}

.footer_top {
  font-size: 12px;
  background: #111;
}

.footer_quick ul,
.footer_top ul {
  display: flex;
  padding: 10px 0px;
}

.footer_top ul li a {
  padding-right: 40px;
}

.footer_quick {
  padding: 10px 0px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.footer_quick ul li a {
  font-size: 14px;
  padding-right: 40px;
  line-height: 1;
}

.footer_info h1 {
  line-height: 70px;
}

.footer_address {
  padding-bottom: 40px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.footer_address h1 img {
  width: 150px;
  margin-bottom: 20px;
}

.footer_address p {
  font-size: 14px;
  opacity: 0.4;
}

.ftr-tel {
  padding: 12px 0px;
  font-size: 14px;
}

.footer_address h4 {
  font-size: 14px;
  opacity: 0.4;
}

.trainer_list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 7%;
  padding: 0 50px;
}

.trainer_list li {
  width: 32%;
  height: 32em;
  margin-bottom: 40px;
  cursor: pointer;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
}

.trainer_list img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.trainer_list li .trainer_txt:hover {
  opacity: 1;
}

.trainer_list li .trainer_txt {
  position: absolute;
  top: 0%;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  text-align: center;
  transition: top 0.6s, opacity 0.6s;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #fff;
}

.trainer_list li .trainer_txt h3 {
  font-size: 3rem;
}

.trainer_list li .trainer_txt p {
  font-size: 1rem;
  line-height: 2rem;
}

.class_list {
  padding: 0 5vw;
  margin-top: 50px;
}

.ham_btn {
  display: none;
  position: absolute;
  border: 0;
  background-color: transparent;
  color: #fff;
  right: 20px;
  top: 50px;
  font-size: 1.5rem;
}

.swiper-slide p {
  position: absolute;
  bottom: 20px;
  left: 20px;
  font-size: 1.5rem;
  color: #fff;
  z-index: 1;
}


.partners .title {
  padding: 7em 5%;
  text-align: left;
}

.partners .title .tit {
  font-size: 3rem;
  font-weight: bold;
  font-family: "Poppins-Bold";
  letter-spacing: 5px;
}

.partners .title .con {
  font-size: 1.4rem;
  font-weight: 400;
}

.partners .title .con br {
  display: none;
}

.partners .swiper {
  overflow: visible;
}

.partners .swiper-slide {
  padding: 40px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 5px 5px 20px rgba(0,0,0,0.12);
  height: 200px;
}

.partners-list {
  display: flex;
  flex-wrap: wrap;
  padding: 0em 5% 0;
  overflow: initial !important;
  /* margin-bottom: 20px; */
  main-height: 600px;
}

.partners-list img {
  /* height: 100%;
  object-fit: cover; */
}

.partners-list .swiper-slide {
}

.wosdd {
  width: 100%;
  height: 100%;
  background: #000;
  color: #fff;
  font-size: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}



@media (max-width: 1460px) {
  .trainer .cont {
    padding: 0 20px;
    margin-top: 20px;
  }

  nav {
    display: none;
  }

  .ham_btn {
    display: block;
  }

  .trainer .txt_box h3 {
    font-size: 20px;
  }

  .banner h1 {
    font-size: 6rem;
  }

  .sub_title h3 {
    font-size: 3rem;
  }

  .wosdd {
    font-size: 2rem
  }


}

@media (max-width: 1080px) {
  .paging {
    display: none;
  }

  .curriculum_list li {
    width: 48%;
    height: 300px !important;
  }

  .trainer .txt_box h3 {
    font-size: 18px;
  }

  .banner h1 {
    font-size: 5rem;
  }

  .trainer_list li {
    width: 48%;
  }

  .trainer .list>li img {
    height: 20em !important;
  }

  .anapich .anpich_txt {
    flex-direction: column;
    text-align: center;
    word-break: keep-all;
    word-wrap: break-word;
    align-items: center;
  }

  .anapich .anpich_txt .txt {
    width: 100%;
    padding: 0 !important;
  }

  .anapich .anpich_txt .txt h3 {
    line-height: 110%;
    margin: 1rem 0;
  }

  .anapich .logo {
    min-height: 250px;
  }

}

@media (max-width: 720px) {
  header {
    justify-content: center;
    align-items: center;
  }

  .banner {
    height: 60vh;
  }

  .paging {
    display: none;
  }

  .curriculum_list li {
    width: 100%;
  }

  .banner h1 {
    font-size: 3rem;
  }

  .sub_title h3 {
    font-size: 2.5rem;
  }

  .title .main_top .txt {
    font-size: 0.8rem;
    word-break: keep-all;
    word-wrap: break-word;
  }

  .trainer_list li {
    width: 100%;
  }

  .trainer_list li {
    width: 100%;
    height: 20em;
  }

  .trainer_list {
    padding: 0 5%;
  }

  footer {
    margin-top: 3em;
  }

}
.trainer_view .img .swiper {
  overflow: hidden !important;
}